import React from "../../_snowpack/pkg/react.js";
import s from "./Note.module.css";
function Note({children, show, onClick, darkTheme}) {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, show && /* @__PURE__ */ React.createElement("div", {
    onClick,
    className: darkTheme ? s.noteDark : s.note,
    dangerouslySetInnerHTML: {__html: children}
  }));
}
export default Note;
