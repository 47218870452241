import React, {useState, useEffect} from "../../_snowpack/pkg/react.js";
import Marked from "../../_snowpack/pkg/marked.js";
import s from "./NotesContainer.module.css";
import Note from "../Note/Note.js";
import NoteEditor from "../NoteEditor/NoteEditor.js";
function NotesContainer({darkTheme}) {
  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown");
  }, []);
  const defaultValue = localStorage.getItem("noteValue") ? localStorage.getItem("noteValue") : "";
  const [noteValue, setNoteValue] = useState(defaultValue);
  const [showNotePreview, setShowNotePreview] = useState(false);
  useEffect(() => {
    localStorage.setItem("noteValue", noteValue);
  }, [noteValue]);
  function handleChange(e) {
    setNoteValue(e.target.value);
  }
  function handleKeydown(e) {
    if (e.key === "Escape") {
      setShowNotePreview(!showNotePreview);
    }
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: s.container
  }, /* @__PURE__ */ React.createElement(NoteEditor, {
    show: !showNotePreview,
    onBlur: () => setShowNotePreview(true),
    onChange: handleChange,
    onKeyDown: handleKeydown,
    darkTheme
  }, noteValue), /* @__PURE__ */ React.createElement(Note, {
    darkTheme,
    onClick: () => setShowNotePreview(false),
    show: showNotePreview
  }, Marked(noteValue)));
}
export default NotesContainer;
