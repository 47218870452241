import React, {useEffect, useState} from "./_snowpack/pkg/react.js";
import s from "./App.module.css";
import NotesContainer from "./components/NotesContainer/NotesContainer.js";
function App() {
  const [darkTheme, setDarkTheme] = useState(true);
  useEffect(() => {
    if (darkTheme)
      document.body.classList.add("dark");
    else
      document.body.classList.remove("dark");
    return () => document.body.classList.remove("dark");
  }, [darkTheme]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("button", {
    className: s.themeToggler,
    onClick: () => setDarkTheme(!darkTheme)
  }, /* @__PURE__ */ React.createElement("img", {
    src: "./theme-icon.svg",
    "aria-hidden": true
  })), /* @__PURE__ */ React.createElement("h1", {
    className: darkTheme ? s.h1Dark : s.h1
  }, "S L A T E"), /* @__PURE__ */ React.createElement(NotesContainer, {
    darkTheme
  }), /* @__PURE__ */ React.createElement("a", {
    href: "https://github.com/nikhilmwarrier",
    className: s.link
  }, "Created by nikhilmwarrier"));
}
export default App;
